<template>
    <div
        v-if="dialog"
    >
        <el-dialog
                :append-to-body="true"

                :visible.sync="dialog"
                :title="title"
                width="1000px"
                :before-close="cancel"
        >
            <el-tabs
                :value="activeName"
                @tab-click="handleClick"
            >
                <el-tab-pane
                    v-if="customInfoTabFlag"
                    label="客户信息"
                    name="first"
                >
                  <el-row>
                    <el-col :span="8">
                      <span class="label">姓名:</span>
                      <span>{{ data.userName ||' ' }}</span>
                    </el-col>
                    <el-col :span="8">
                      <span class="label">国籍:</span>
                      <span>{{ data.nationName}}</span>
                    </el-col>
                    <el-col :span="8">
                      <span class="label">性别:</span>
                      <span>{{ data.gender == 2 ? '女': '男'}}</span>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8">
                      <span class="label">电话号码:</span>
                      <span>{{ data.phone}}</span>
                    </el-col>
                    <el-col :span="8">
                      <span class="label">邮箱:</span>
                      <span>{{ data.email}}</span>
                    </el-col>
                    <el-col :span="8">

                      <span class="label">生日:</span>
                      <span>{{data.birthday}}</span>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8">
                      <span class="label">客户类型:</span>
                      <span>{{ data.custType==3?'普通客户':'代理' }}</span>
                    </el-col>
                    <el-col :span="8">
                      <span class="label">{{data.custType!=3?'代理状态':'客户状态'}}:</span>
                      <span>{{ parseIbStatus(data.auditStatus) }}</span>
                    </el-col>
                    <el-col :span="8">
                      <span class="label">{{data.custType!=3?'代理级别':'客户级别'}}:</span>
                      <span>{{ data.levelName}}</span>
                    </el-col>
                  </el-row>
                  <el-row>
                      <el-col :span="8">
                          <span class="label">注册时间:</span>
                          <span>{{ parseTime(data.applyDate)}}</span>
                      </el-col>
                      <el-col :span="8">
                          <span class="label">申请时间:</span>
                          <span>{{ parseTime(data.createTime)}}</span>
                      </el-col>
                      <el-col v-if="data.custType!=3" :span="8">
                          <span class="label">代理层级:</span>
                          <span>{{ parseIbRank(data.ibRank)}}</span>
                      </el-col>
                  </el-row>
                  <el-row>
                      <el-col>
                          <span class="label">客户注册链接:</span>
                          <span>{{ data.linkForCust }}</span>
                      </el-col>
                  </el-row>
                  <el-row
                          v-if="isShowSubLink"
                          v-for="(item,index) in subAgentList"
                          :key="index"
                  >
                      <el-col>
                          <span class="label">{{item.levelName}}:</span>
                          <span>{{item.link}}</span>
                      </el-col>
                  </el-row>
                  <el-row
                          v-if="isShowSubLink"
                          v-for="(item,index) in childrenSubAgentList"
                          :key="index"
                  >
                      <el-col>
                          <span class="label">{{item.levelName}}</span>
                          <span>{{item.link}}</span>
                      </el-col>
                  </el-row>
                </el-tab-pane>
                <el-tab-pane
                    v-if="detailTabFlag"
                    label="详细资料"
                    name="second"
                >
                  <customer-detail-info
                    :cust-id="data.id"
                  ></customer-detail-info>
                </el-tab-pane>
                <el-tab-pane
                        v-if="walletInfoTabFlag"
                        label="钱包信息"
                        name="third"
                >
                <el-row>
                  <el-col :span="12">
                            <span class="label">账户币种:</span>
                            <span>{{ backInfo && backInfo.currency }}</span>
                        </el-col>
                </el-row>
                    <el-row>
                       
                        <el-col :span="12">
                            <span class="label">银行名称:</span>
                            <span>{{ backInfo && backInfo.bankName }}</span>
                        </el-col>
                        <el-col :span="12">
                            <span class="label">银行帐号:</span>
                            <span>{{backInfo && backInfo.bankAccount }}</span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <span class="label">银行网点:</span>
                            <span>{{ backInfo && backInfo.bankOutlet}}</span>
                        </el-col>
                        <el-col :span="12">
                            <span class="label">SwiftCode:</span>
                            <span>{{ backInfo && backInfo.swiftCode }}</span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col>
                            <span class="label">银行卡照片:</span>
                            <el-image
                                    v-if="backInfo && backInfo.bankFrontImg"
                                    style="width: 170px;height: 120px"
                                    :src="backInfo && backInfo.bankFrontImg"
                                    :preview-src-list="[backInfo.bankFrontImg]"
                            />
                            <el-image
                                    v-if="backInfo && backInfo.bankBackImg"
                                    style="width: 170px;height: 120px;margin-left: 10px"
                                    :src="backInfo && backInfo.bankBackImg"
                                    :preview-src-list="[backInfo.bankBackImg]"
                            />
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col>
                            <span class="label">USDT:</span>
                            <span class="label-right">
                <template v-if="usdtInfo && usdtInfo.usdtQrPic">
                  <el-image
                          v-if="usdtInfo && usdtInfo.usdtQrPic"
                          style="width: 90px;height: 90px"
                          :src="usdtInfo && usdtInfo.usdtQrPic"
                          :preview-src-list="[usdtInfo.usdtQrPic]"
                  />
                  <br>
                <span style="margin-left: 80px">{{usdtInfo && usdtInfo.usdtAdress}}</span>
                </template>
                <template v-else>
                  <span>暂无信息</span>
                </template>
              </span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col>
                            <span class="label">OTC365:</span>
                            <span class="label-right">
                <template v-if="otcInfo && otcInfo.otcQrPic">
                  <el-image
                          v-if="otcInfo && otcInfo.otcQrPic"
                          style="width: 90px;height: 90px"
                          :src="otcInfo && otcInfo.otcQrPic"
                          :preview-src-list="[otcInfo.otcQrPic]"
                  />
                  <br>
                  <span style="margin-left: 90px;">{{otcInfo && otcInfo.otcAdress}}</span>
                </template>
                <template v-else>
                  <span>暂无信息</span>
                </template>
              </span>
                        </el-col>
                    </el-row>
                </el-tab-pane>

                <el-tab-pane
                    v-if="accountListTabFlag"
                    label="账户列表"
                    name="fourth"
                >
                    <el-table :data="mt4Accounts" style="width: 100%">
                        <el-table-column prop="id" label="编号" width="100"/>
                        <el-table-column label="账户类型" width="120">
                            <template slot-scope="scope">
                                <span>{{ getAccountType(scope.row) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="userName" label="用户名"/>
                        <el-table-column prop="login" label="MT4账户"/>
                        <el-table-column prop="leverage" label="杠杆" width="150">
                            <template slot-scope="scope">
                                <span>1:{{ scope.row.leverage }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="createTime" label="操作时间" width="180">
                            <template slot-scope="scope">
                                <span>{{ parseTime(scope.row.createTime) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <el-button size="mini" @click="viewCust(scope.$index, scope.row,true)">查看</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>

                <el-tab-pane
                    v-if="auditLogTabFlag"
                    label="审核记录"
                    name="fifth"
                >
                    <el-table :data="events" style="width: 100%">
                        <el-table-column prop="id" label="序号" width="80"/>
                        <el-table-column prop="eventName" label="操作类型"/>
                        <el-table-column prop="eventContent" label="审核意见">
                            <template slot-scope="scope">
                                <span v-if="!scope.row.reasons.length">-</span>
                                <template v-else>
                                    <div
                                            v-for="(item,index) in scope.row.reasons"
                                            :key="index"
                                    >{{item}}</div>
                                </template>
                            </template>
                        </el-table-column>
                        <el-table-column prop="createTime" label="操作时间" width="180">
                            <template slot-scope="scope">
                                <span>{{ parseTime(scope.row.createTime) }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
            </el-tabs>
            <br/>
            <div v-if="isCheck" class="dialog-footer">
                <el-row v-if="data.auditStatus!=4">
                    <span class="label">审核结论:</span>
                    <el-radio-group
                            class="label"
                            v-model="decision"
                            :disabled="data.auditStatus=='4'"
                    >
                        <el-radio
                                :label="item.key"
                                :key="item.key"
                                v-for="item in chkStatusOptions1"
                        >{{item.display_name}}
                        </el-radio>
                    </el-radio-group>
                </el-row>
                <el-row v-if="decision == 2">
                    <el-col :span="3">
                        <div class="reject-text">拒绝原因:</div>
                    </el-col>
                    <el-col :span="21">
                        <el-select
                                v-model="rejectReason"
                                class="select-el"
                                placeholder="请选择"
                                multiple
                        >
                            <el-option
                                    v-for="(item,index) in rejectReasonOption"
                                    :label="item.value"
                                    :key="index"
                                    :value="item.value"
                            >
                            </el-option>
                            <el-option
                                    label="自定义"
                                    value="自定义"
                            >
                            </el-option>
                        </el-select>
                        <div v-if="rejectReason.includes('自定义')" class="textarea-outer">
                            <el-input
                                    class="textarea-el"
                                    type="textarea"
                                    v-model="rejectReasonSelf"
                            ></el-input>
                        </div>
                    </el-col>
                </el-row>
                <el-row v-if="identifyValidModulesShow">
                    <span class="label">身份证验证:</span>
                    <el-radio-group v-model="identifyStatus">
                        <el-radio :label="3">通过</el-radio>
                        <el-radio :label="2">不通过</el-radio>
                    </el-radio-group>
                </el-row>
                <el-row v-if="identifyValidModulesShow && identifyStatus == 2">
                    <el-col :span="3">
                        <div class="reject-text">拒绝原因:</div>
                    </el-col>
                    <el-col :span="21">
                        <el-select
                                v-model="identifyRejectReason"
                                class="select-el"
                                placeholder="请选择"
                                multiple
                        >
                            <el-option
                                    v-for="(item,index) in identifyRejectReasonOption"
                                    :label="item.value"
                                    :key="index"
                                    :value="item.value"
                            >
                            </el-option>
                            <el-option
                                    label="自定义"
                                    value="自定义"
                            >
                            </el-option>
                        </el-select>
                        <div
                                v-if="identifyRejectReason.includes('自定义')"
                                class="textarea-outer"
                        >
                            <el-input
                                    class="textarea-el"
                                    type="textarea"
                                    v-model="identifyRejectReasonSelfDefined"
                            ></el-input>
                        </div>
                    </el-col>
                </el-row>

                <el-row v-if="handleCertImgUrlValidModulesShow">
                    <span class="label">手持证件照片验证:</span>
                    <el-radio-group v-model="handleCertStatus">
                        <el-radio :label="3">通过</el-radio>
                        <el-radio :label="2">不通过</el-radio>
                    </el-radio-group>
                </el-row>
                <el-row v-if="handleCertImgUrlValidModulesShow && handleCertStatus == 2">
                    <el-col :span="3">
                        <div class="reject-text">拒绝原因:</div>
                    </el-col>
                    <el-col :span="21">
                        <el-select
                                v-model="handleCertRejectReason"
                                class="select-el"
                                placeholder="请选择"
                                multiple
                        >
                            <el-option
                                    v-for="(item,index) in handleCertReasonOption"
                                    :label="item.value"
                                    :key="index"
                                    :value="item.value"
                            >
                            </el-option>
                            <el-option
                                    label="自定义"
                                    value="自定义"
                            >
                            </el-option>
                        </el-select>
                        <div
                                v-if="handleCertRejectReason.includes('自定义')"
                                class="textarea-outer"
                        >
                            <el-input
                                    class="textarea-el"
                                    type="textarea"
                                    v-model="identifyRejectReasonSelfDefined"
                            ></el-input>
                        </div>
                    </el-col>
                </el-row>
                <el-row v-if="adressValidModulesShow">
                    <span class="label">地址验证:</span>
                    <el-radio-group v-model="addressStatus">
                        <el-radio :label="3">通过</el-radio>
                        <el-radio :label="2">不通过</el-radio>
                    </el-radio-group>
                </el-row>
                <el-row v-if="adressValidModulesShow && addressStatus == 2">
                    <el-col :span="3">
                        <div class="reject-text">拒绝原因:</div>
                    </el-col>
                    <el-col :span="21">
                        <el-select
                                class="select-el"
                                v-model="adressRejectReason"
                                placeholder="请选择"
                                multiple
                        >
                            <el-option
                                    v-for="(item,index) in addressRejectReasonOption"
                                    :label="item.value"
                                    :key="index"
                                    :value="item.value"
                            >
                            </el-option>
                            <el-option
                                    label="自定义"
                                    value="自定义"
                            >
                            </el-option>
                        </el-select>
                        <div
                                v-if="adressRejectReason.includes('自定义')"
                                class="textarea-outer"
                        >
                            <el-input
                                    class="textarea-el"
                                    type="textarea"
                                    v-model="adressRejectReasonSelfDefined"
                            ></el-input>
                        </div>
                    </el-col>
                </el-row>
                <el-row v-if="bankPaneAuthTextFlag">
                    <span class="reject-text">银行卡验证: </span>
                    <el-radio-group v-model="bankStatus">
                        <el-radio :label="3">通过</el-radio>
                        <el-radio :label="2">不通过</el-radio>
                    </el-radio-group>
                </el-row>
                <el-row v-if="bankPaneAuthTextFlag && bankStatus == 2">
                    <el-col :span="3">
                        <div class="reject-text">拒绝原因:</div>
                    </el-col>
                    <el-col :span="21">
                        <el-select
                                class="select-el"
                                v-model="bankRejectReason"
                                placeholder="请选择"
                                multiple
                        >
                            <el-option
                                    v-for="(item,index) in bankRejectReasonOption"
                                    :label="item.value"
                                    :key="index"
                                    :value="item.value"
                            >
                            </el-option>
                            <el-option
                                    label="自定义"
                                    value="自定义"
                            >
                            </el-option>
                        </el-select>
                        <div v-if="bankRejectReason.includes('自定义')" class="textarea-outer">
                            <el-input
                                    class="textarea-el"
                                    type="textarea"
                                    v-model="bankRejectReasonSelfDefined"
                            ></el-input>
                        </div>
                    </el-col>
                </el-row>
                <!--少数民族限制 todo-->
                <!--客服部审核才有,其他的没有 todo-->
                <el-row
                    v-if="tableStatus == 1"
                >
                  <span class="label">少数民族风控:</span>
                  <el-radio v-model="riskControlFlag" :label="1">限制</el-radio>
                  <el-radio v-model="riskControlFlag" :label="0">不限制</el-radio>
                </el-row>
            </div>
            <div v-if="isCheck" class="dialog-footer" slot="footer">
                <el-button type="text" @click="cancel">取消</el-button>
                <el-button :loading="loading" type="primary" @click="doCheck()">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
  import customerDetailInfo from '@/components/public/customerDetailInfo'
    import { getVerifiedInfo } from '@/api/system/user'
    import { getDict } from '@/api/system/dict'
  import { audit, auditImg } from '@/api/cus/tCustInfo'
  import { parseTime } from '@/utils/index'
  import checkPermission from '@/utils/permission'
  import { initData,getDictListByType } from '@/api/data'
  import {
    parseType,
    parseIbStatus,
    parseSource,
    parseIbRank
  } from '@/views/pt/ib/utils/index'
  import { mapGetters } from 'vuex'
  import { querySubLevleAndRules, querySubAgentList } from '@/api/ib/tManager.js'
  /*
  * custStatus	客户状态	N	整型
  * 1、注册未申请 2、已申请未开户 3、开户未入金 4、入金未交易 5、已交易 6、销户 10、注册未激活
auditStatus	审核状态	N	整型
* 1、客服审核 2、交易部审核 3、审核拒绝 4、审核通过 5、冻结 6、销户 7、账户信息复审
* */
  export default {
    components:{
      customerDetailInfo
    },
    props: {
      tableStatus: {},
      isCheck: {
        type: Boolean,
        required: true
      },
      sup_this: {
        type: Object,
        default: null
      },
      data: {
        type: Object,
        default: null
      },
      isShowSubLink: {
        type: Boolean,
        default: true
      }
    },
    data() {
      let _this = this;
      return {
        activeName: 'first',
        subAgentList: [],
        childrenSubAgentList: [],
        loading: false,
        openLoading: false,
        dialog: false,
        events: null,

        mtServer: '',
        mtGroup: '',
        mtLeverage: '',
        mtAccout: '',
        title: '',
        decision: 1,
        isRefuseReason: false,

        identifyStatus: 3,
        handleCertStatus: 3,
        addressStatus: 3,
        bankStatus: 3,
        groupList: [],
        leverList: [],
        chkStatusOptions1: [
          {
            key: 1,
            display_name: '通过'
          },
          {
            key: 2,
            display_name: '拒绝'
          }
        ],
        mt4Accounts: null,
        HasWithGoldActivity: false,

        rejectReason: '',
        rejectReasonOption:[],
        rejectReasonSelf: '',

        identifyRejectReason: '',
        identifyRejectReasonOption:[],
        identifyRejectReasonSelfDefined: '',

        handleCertRejectReason: '',
        handleCertReasonOption:[],
        handleCertRejectReasonSelfDefined: '',

        adressRejectReason: '',
        addressRejectReasonOption: [],
        adressRejectReasonSelfDefined: '',

        bankRejectReason: '',
        bankRejectReasonOption: [],
        bankRejectReasonSelfDefined: '',

        riskControlFlag: 0,
      }
    },
    computed: {

      isReject() {
        if (this.data.auditStatus != 2 && this.data.auditStatus != 4) {
          return this.decision == 2
        } else if (this.data.auditStatus == 2 && this.data.auditStatus != 4) {
          return this.decision == 2
        } else {
          return false
        }
      },
      bankPaneFlag() {
        if (this.tableStatus == 1 || this.tableStatus == 2) {
          return this.data.bankAccount
        } else {
          return true
        }
      },
      bankPaneAuthTextFlag() {
        if(this.decision == 1){
          if (this.tableStatus == 1 || this.tableStatus == 2) {
            return this.data.bankAccount && this.data.bankStatus != '3'
          } else {
            return this.data.bankName && this.data.bankStatus != '3'
          }
        }else {
          return false;
        }
      },
      identifyValidModulesShow() {
        // 交易部审核不需要显示
        return this.decision == 1 && this.data.identifyStatus == '1' && this.tableStatus != '2';
      },
      handleCertImgUrlValidModulesShow() {
        // 交易部审核不需要显示
        return this.data.handleCertImg && this.decision == 1 && this.data.handleCertIdentifyStatus == '1' && this.tableStatus != '2';
      },
      adressValidModulesShow() {
        //待审核状态才需要审核
        // 交易部审核不需要显示
        return this.decision == 1 && this.data.addressStatus == '1' && this.tableStatus != '2';
      },
      backInfo() {
        if (this.data) {
          if (this.data.collections && this.data.collections.length) {
            for (let i = 0; i < this.data.collections.length; i++) {
              if (this.data.collections[i].payTypeId == 4) {
                if (this.data.collections[i].custPayDetail) {
                  return {
                    bankAccount: this.data.collections[i].custPayDetail.bankAccount,
                    bankName: this.data.collections[i].custPayDetail.bankName,
                    bankOutlet: this.data.collections[i].custPayDetail.bankOutlet,
                    swiftCode: this.data.collections[i].custPayDetail.swiftCode,
                    bankFrontImg: this.data.collections[i].custPayDetail.bankFrontImg,
                    bankBackImg: this.data.collections[i].custPayDetail.bankBackImg,
                    currency: this.data.collections[i].custPayDetail.currency 
                  }
                }
              }
            }
          }
        } else {
          return {
            bankAccount: '',
            bankName: '',
            bankOutlet: '',
            swiftCode: '',
            bankFrontImg: '',
            bankBackImg: ''
          }
        }
      },
      usdtInfo() {
        if (this.data) {
          if (this.data.collections && this.data.collections.length) {
            for (let i = 0; i < this.data.collections.length; i++) {
              if (this.data.collections[i].payTypeId == 5) {
                if (this.data.collections[i].custPayDetail) {
                  return {
                    usdtQrPic: this.data.collections[i].custPayDetail.qrPic,
                    usdtAdress: this.data.collections[i].custPayDetail.address
                  }
                }
              }
            }
          }
        } else {
          return {
            usdtQrPic: '',
            usdtAdress: ''
          }
        }
      },
      otcInfo() {
        if (this.data) {
          if (this.data.collections && this.data.collections.length) {
            for (let i = 0; i < this.data.collections.length; i++) {
              if (this.data.collections[i].payTypeId == 6) {
                if (this.data.collections[i].custPayDetail) {
                  return {
                    otcQrPic: this.data.collections[i].custPayDetail.qrPic,
                    otcAdress: this.data.collections[i].custPayDetail.address
                  }
                }
              }
            }
          }
        } else {
          return {
            otcQrPic: '',
            otcAdress: ''
          }
        }
      },
      customInfoTabFlag(){
        return !this.isCheck && checkPermission(['ADMIN','CUST_INFO_ALL','CUST_INFO_BASIC']);
      },
      detailTabFlag(){
        return checkPermission(['ADMIN','CUST_INFO_DETAIL','CUST_INFO_ALL']);
      },
      walletInfoTabFlag(){
        return this.bankPaneFlag && checkPermission(['ADMIN','CUST_INFO_WALLET','CUST_INFO_ALL'])
      },
      accountListTabFlag(){
        return checkPermission(['ADMIN','CUST_INFO_ACCOUNTS','CUST_INFO_ALL']);
      },
      auditLogTabFlag(){
        return checkPermission(['ADMIN','CUST_INFO_AUDIT_LOG','CUST_INFO_ALL'])
      }
    },
    watch: {
      data: {
        handler(newVal) {
          this.getSubLevelAndRule(newVal.id)
          this.getChildrenAgentList(newVal.id);
          if(this.accountListTabFlag){
            this.getMt4Accounts();
          }
          if(this.auditLogTabFlag){
            this.getEvents();
          }
          if(this.isCheck){
            this.getAuditReasonsDist();
          }
        },
        deep: true
      },
    },
    created() {
      this.activeName = this.customInfoTabFlag
          ? 'first': this.detailTabFlag
              ? 'second':  this.walletInfoTabFlag
                  ? 'third': this.accountListTabFlag
                      ? 'fourth': 'fifth';
      if (this.data != null) {
        this.mtGroup = this.data.group
        this.mtLeverage = this.data.leverage
        this.mtAccout = this.data.mt4MainAcc
      };
    },
    methods: {
      parseType,
      parseIbStatus,
      parseTime,
      checkPermission,
      parseSource,
      parseIbRank,
      getAccountType(row) {
        if (row.businessType == 6) {
          return '体验账户'
        } else if (row.businessType == 5) {
          return '模拟账户'
        } else if (row.businessType == 3) {
          return '钱包账户'
        } else if (row.businessType == 1) {
          if (row.tier == 1) {
            return 'Social(信号)'
          } else {
            return 'Social(投资)'
          }
        } else if (row.businessType == 2) {
          if (row.tier == 1) {
            return 'MAM(信号)'
          } else {
            return 'MAM(投资)'
          }
        } else {
          return '普通账户'
        }
      },
      getSubLevelAndRule(custId) {
        if (custId) {
          querySubLevleAndRules({
            custId: custId
          }).then((res) => {
            this.subAgentList = res.data
          })
        }
      },
      getChildrenAgentList(custId) {
        if (custId) {
          querySubAgentList({
            custId: custId
          }).then((res) => {
            this.childrenSubAgentList = res
          })
        }
      },
      viewCust(index, row, isReadOnly) {
        this.$router.push({
          name: 'accdetail',
          query: {
            serverId: row.serverId + '',
            login: row.login + '',
            isReadOnly: isReadOnly
          }
        })
        this.dialog = false
      },
      parseString(data) {
        return data.toString()
      },
      cancel() {
        this.dialog = false
        this.loading = false
        this.openLoading = false
      },
      handleClick(tab, event) {
        this.activeName = tab.name;
        if (tab.name == 'fifth') {
          this.getEvents()
        }
        if (tab.name == 'fourth') {
          this.getMt4Accounts()
        }
      },
      getEvents() {
        var url = 'crm/tCustEvent'
        var params = { custId: this.data.id, sort: 'createTime,desc' }
        initData(url, params)
          .then(res => {
            this.events = res.content
          })
          .catch(err => {
            console.log(err.response.data.message)
          })
        return true
      },
      getMt4Accounts() {
        var url = 'crm/getTCustMtaccountsAndCustInfos'
        if (this.data.id !== undefined && this.data.id !== '' && this.data.id !== null) {
          var params = { custInfo: this.data.id, sort: 'cm.createTime,desc' }
          initData(url, params).then(res => {
            this.mt4Accounts = res.content
          }).catch(err => {
            console.log(err.response.data.message)
          })
        }
        return true
      },
      getAuditReasonsDist(){
        getDict({
          type: 'auditCustRejectReason'
        }).then((res) => {
          this.rejectReasonOption = res.content;
        });
        getDict({
          type: 'auditIdentifyRejectReason'
        }).then((res) => {
          this.identifyRejectReasonOption = res.content;
          this.handleCertReasonOption = res.content;
        });
        getDict({
          type: 'auditAddressRejectReason'
        }).then((res) => {
          this.addressRejectReasonOption = res.content;
        });
        getDict({
          type: 'auditBankRejectReason'
        }).then((res) => {
          this.bankRejectReasonOption = res.content;
        })
      },
      doCheck(status) {
        const params = {
          id: this.data.id,
          auditBy: this.data.auditStatus
        }
        if(this.tableStatus == 1){
          params.riskControlFlag = this.riskControlFlag;
        }

        if (this.identifyValidModulesShow && this.identifyStatus) {
          params.identifyStatus = this.identifyStatus;
          if(this.identifyStatus == 2){
            if(this.identifyRejectReason.includes('自定义')){
              params.identifyRejectReason = this.deletedefined(this.identifyRejectReason.join(';') + ';'+ this.identifyRejectReasonSelfDefined);
            }else {
              params.identifyRejectReason = this.identifyRejectReason.join(';');
            }
          }
        }
        if (this.handleCertImgUrlValidModulesShow && this.handleCertStatus) {
          params.handleCertIdentifyStatus = this.handleCertStatus;
          if(this.handleCertStatus == 2){
            if(this.handleCertRejectReason.includes('自定义')){
              params.handleCertRejectReason = this.deletedefined(this.handleCertRejectReason.join(';') + ';'+ this.identifyRejectReasonSelfDefined);
            }else {
              params.handleCertRejectReason = this.handleCertRejectReason.join(';');
            }
          }
        }
        if (this.adressValidModulesShow && this.addressStatus) {
          params.addressStatus = this.addressStatus;
          if(this.addressStatus == 2){
            if(this.adressRejectReason.includes('自定义')){
              params.addressRejectReason = this.deletedefined(this.adressRejectReason.join(';') + ';' + this.adressRejectReasonSelfDefined);
            }else {
              params.addressRejectReason = this.adressRejectReason.join(';');
            }
          }
        }
        if (this.bankPaneAuthTextFlag && this.bankStatus) {
          params.bankStatus = this.bankStatus;
          if(this.bankStatus == 2){
            if(this.bankRejectReason.includes('自定义')){
              params.bankRejectReason = this.deletedefined(this.bankRejectReason.join(';') + ';' + this.bankRejectReasonSelfDefined);
            }else {
              params.bankRejectReason = this.bankRejectReason.join(';');
            }
          }
        }
        if (this.data.auditStatus != '4') {
          params.decision = this.decision;
          if(this.decision == 2){
            if (this.rejectReason.includes('自定义')) {
              params.rejectReason = this.deletedefined(this.rejectReason.join(';')+ ';' + this.rejectReasonSelf);
            }else {
              params.rejectReason = this.rejectReason.join(';');
            }
          }
          this.loading = true;
          audit(params)
            .then(res => {
              this.$notify({
                title: '提交成功',
                type: 'success',
                duration: 1500
              })
              this.loading = false
              this.dialog = false
              this.sup_this.init()
            })
            .catch(err => {
              this.loading = false
              // this.dialog = false;
              console.log(err.response.data.message)
            })
        } else {
          this.loading = true
          auditImg(params)
            .then(res => {
              this.$notify({
                title: '提交成功',
                type: 'success',
                duration: 1500
              })
              this.loading = false
              this.dialog = false
              this.sup_this.init()
            })
            .catch(err => {
              this.loading = false;
            })
        }
      },
      deletedefined(string){
        return string.replace('自定义;','');
      },
    }
  }
</script>

<style scoped>
    .el-row {
        margin-bottom: 20px;
        padding: 10px 0;
    }

    .el-col {
        border-radius: 4px;
    }

    .label {
        padding: 10px 10px;
    }
    .reject-text,
    .label {
        font-size: 18px;
    }
    .textarea-el,
    .select-el{
        width: 400px;
    }
    .textarea-outer {
        margin-top: 10px;
    }
</style>
<style lang="less" scoped>
.border-box {
  height: 1px;
  background-color: #dddddd;
}
</style>
